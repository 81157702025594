import { IonBackButton, IonButton, IonButtons, IonCardTitle, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonPage, IonRouterLink, IonRow, IonToolbar } from '@ionic/react';
import styles from './Login.module.scss';
import { Link } from 'react-router-dom';
import { arrowBack, shapesOutline } from "ionicons/icons";
import CustomField from '../components/CustomField';
import { useLoginFields } from '../data/fields';
import { Action } from '../components/Action';
import { Wave } from '../components/Wave';
import { useEffect, useState } from 'react';
import { validateForm } from '../data/utils';
import { useParams } from 'react-router';
import Logo from '../components/ddafricalogo.jpg';

const Login = () => {
    
    const params = useParams();

    const fields = useLoginFields();
    const [ errors, setErrors ] = useState(false);

    const login = () => {

        const errors = validateForm(fields);
        setErrors(errors);

        if (!errors.length) {

            //  Submit your form here
        }
    }

    useEffect(() => {

        return () => {

            fields.forEach(field => field.input.state.reset(""));
            setErrors(false);
        }
    }, [params]);

	return (
		<IonPage className={ styles.loginPage }>
			<IonHeader>
				<IonToolbar>
					
                    <IonButtons slot="start">
                        <IonBackButton icon={ arrowBack } text="" className="custom-back" />
                    </IonButtons>

                    <IonButtons slot="end">
                        <Link to="/home">
                            <IonButton className="custom-button">
                                <IonIcon icon={ shapesOutline } />
                            </IonButton>
                        </Link>
                    </IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
            <div align="center" className="moveupimg">
            <img  className="logoimg" src={Logo} style={{width: '80px'}} alt="logo" /></div>
                <IonGrid className="ion-padding moveup">
                    <IonRow>
                        <IonCol size="12" className={ styles.headingText }>
                            <IonCardTitle><b>Log in</b></IonCardTitle>
                            <h5>Welcome back, hope you're doing well</h5>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top ion-padding-top">
                        <IonCol size="12">

                            {   fields.map((field,index) => {
                                    return <span key={index}><CustomField field={ field } errors={ errors } /></span>;
                                })
                            }

                            <IonButton className="custom-button" expand="block" onClick={ login }>Login</IonButton>
                        </IonCol>
                    </IonRow>
                    <Action message="Lost Password?" text="Click Here To Reset" link="/reset" />
                </IonGrid>
                <div className="topmarginer">
                    <IonGrid className="ion-no-margin ion-no-padding">

                        <Action message="Don't have an account?" text="Sign up" link="/signup" />
                    
                    </IonGrid>
                </div>
			</IonContent>

			<IonFooter>
            <Wave />
			</IonFooter>
		</IonPage>
	);
};

export default Login;