import { IonButton, IonCardTitle, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonImg, IonPage, IonRouterLink, IonRow, IonToolbar } from '@ionic/react';
import { Action } from '../components/Action';
import styles from './Home.module.scss';
import { Wave } from '../components/WaveHome';
import ddAfricaLogo from '../components/ddafricalogo.jpg';

const Home = () => {
	
	return (
		<IonPage className={ styles.homePage }>
			<IonHeader>
				{/* <IonToolbar className="ion-no-margin ion-no-padding"> */}
					<img  src="/assets/banner1234.png" className="wavy logoimg-home" alt="banner" />
				{/* </IonToolbar> */}
			</IonHeader>
			<IonContent fullscreen>
			<div align="center" className="moveupimgx show-logo-home" style={{display: 'none'}}>
				<img src={ddAfricaLogo} style={{width: '80px'}} alt="logo" />
			</div>
				<div className={ styles.getStarted }>
					<IonGrid>
						<IonRow className={ `ion-text-center ion-justify-content-center ${ styles.heading }` }>
							<IonCol size="11" className={ styles.headingText }>
								<IonCardTitle>Join D.D. Sunlola Network ::) We empower youth with business skills and capital.</IonCardTitle>
							</IonCol>
						</IonRow>

						<IonRow className={ `ion-text-center ion-justify-content-center` }>
							<IonRouterLink routerLink="/signup" className="custom-link">
								<IonCol size="11">
									<IonButton className={ `${ styles.getStartedButton } custom-button` }>Get started &rarr;</IonButton>
								</IonCol>
							</IonRouterLink>
						</IonRow>
					</IonGrid>
				</div>
			</IonContent>

			<IonFooter>
				{/* <IonGrid>
				<Wave />
					<Action message="Already got an account?" text="Login" link="/login" />
				</IonGrid> */}
				<span className="ion-no-margin ion-no-padding">

					<Action message="Already got an account?" text="Login" link="/login" /> 
                    <Wave />
				</span>
			</IonFooter>
		</IonPage>
	);
};

export default Home;