import React, { useState, useEffect } from 'react';
import { Plugins } from '@capacitor/core';
import '@capacitor-community/contacts';

const { Contacts } = Plugins;

const ContactsTable = () => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const getContacts = async () => {
      try {
        const permission = await Contacts.requestPermissions();
        if (permission.contacts === 'granted') {
          const result = await Contacts.getContacts();
          setContacts(result.contacts);
        } else {
          console.error('Permission not granted for contacts');
        }
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    const getContacts2 = async () => {
      try {
        const fields = ['name', 'phones'];
        const options = { multiple: true };
        const foundContacts = await Contacts.find(fields, options);
        setContacts(foundContacts);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    getContacts2();
  }, []);

  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Phone Numbers</th>
        </tr>
      </thead>
      <tbody>
        {contacts.map((contact, index) => (
          <tr key={index}>
            <td>{contact.displayName || 'No Name'}</td>
            <td>
              {contact.phoneNumbers && contact.phoneNumbers.map((phone, idx) => (
                <div key={idx}>{phone.number}</div>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ContactsTable;
