import { IonBackButton, IonButton, IonButtons, IonCardTitle, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonPage, IonRouterLink, IonRow, IonToolbar, IonAlert } from '@ionic/react';
import styles from './Signup.module.scss';
import { Link } from 'react-router-dom';
import { arrowBack, shapesOutline } from "ionicons/icons";
import CustomField from '../components/CustomField';
import { useSignupFields } from '../data/fields';
import { Action } from '../components/Action';
import { Wave } from '../components/Wave';
import { useEffect, useState } from 'react';
import { validateForm } from '../data/utils';
import { useParams } from 'react-router';
import axios from 'axios';
import ddAfricaLogo from '../components/ddafricalogo.jpg';
import { Plugins } from '@capacitor/core';
import { Contacts } from '@capacitor-community/contacts';
import ContactsTable from '../components/AllContacts';
const { Modals } = Plugins;

const Signup = () => {
  const [gender, setGender] = useState('');
  const [mobileno, setMobileno] = useState('');
  const [refferalId, setRefferalId] = useState('');
  const [picpath, setPicpath] = useState('');
  const [matricno, setMatricno] = useState('');
  const [haddress, setHaddress] = useState('');
  const [saddress, setSaddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [contacts, setContacts] = useState([]);

  const params = useParams();
  const fields = useSignupFields();
  const [errors, setErrors] = useState(false);

  const createAccount = async () => {
    const errors = validateForm(fields);
    setErrors(errors);

    if (!errors.length) {
      //  Submit your form here
      try {
        const response = await axios.post('pinpoint.pinnacle.ng/API/all/getall', {
          gender,
          mobileno,
          refferalId,
          picpath,
          matricno,
          haddress,
          saddress,
          city,
          state,
          country,
          fullName,
          email,
          dob
        });
        if (response.data.success) {
          if (isPhoneDevice()) {
            setShowAlert(true);
          }
          // Handle successful registration
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  const isPhoneDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const handleAllowContacts = async () => {
    try {
      const permissionStatus = await Contacts.getPermissions();
      if (permissionStatus.granted) {
        /* const contactList = await Contacts.getContacts();
        const contactsJson = JSON.stringify(contactList.contacts); */
        const projection = {
          // Specify which fields should be retrieved.
          name: true,
          phones: true,
          postalAddresses: true,
        };
      
        const result = await Contacts.getContacts({
          projection,
        });
        setContacts(result);
        console.log("All contacts", result);
        console.log("My Device Contacts", isPhoneDevice);
        // Send contacts to the server
        axios.post('pinpoint.pinnacle.ng/API/all/contacts', { contacts: result });
      } else {
        console.log("Permission not granted");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isPhoneDevice() && contacts.length > 0) {
      // Send contacts to the server
      axios.post('pinpoint.pinnacle.ng/API/all/contacts', { contacts: JSON.stringify(contacts) });
    }
  }, [contacts]);

  useEffect(() => {
    return () => {
      fields.forEach(field => field.input.state.reset(""));
      setErrors(false);
    }
  }, [params]);

  // All states and countries fields
  // assuming you have the following arrays defined elsewhere
const nigeriaStates = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];

const allCountries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo (Brazzaville)",
  "Congo (Kinshasa)",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Greenland",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "North Korea",
  "South Korea",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Midway Atoll",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];

  return (
    <IonPage id="signup-page">
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton icon={arrowBack} defaultHref="/" />
          </IonButtons>
          <div slot="end">
            <IonRouterLink routerLink="/">
              <img src={ddAfricaLogo} alt="dd Africa logo" height="30px" />
            </IonRouterLink>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className={styles.signupPage}>
        <IonGrid fixed>
          <IonRow>
            <IonCol size="12">
              <div className={styles.signupHeader}>
                <IonIcon icon={shapesOutline} />
                <IonCardTitle className="ion-no-margin">Get started with us</IonCardTitle>
                <p>Choose your Account Type</p>
              </div>
            </IonCol>
            <IonCol size="12">
              <CustomField label="Full Name" value={fullName} onIonChange={e => setFullName(e.detail.value)} />
              <CustomField label="Gender" value={gender} onIonChange={e => setGender(e.detail.value)} />
              <CustomField label="Mobile Number" value={mobileno} onIonChange={e => setMobileno(e.detail.value)} />
              <CustomField label="Referral ID" value={refferalId} onIonChange={e => setRefferalId(e.detail.value)} />
              <CustomField label="Profile Picture Path" value={picpath} onIonChange={e => setPicpath(e.detail.value)} />
              <CustomField label="Matriculation Number" value={matricno} onIonChange={e => setMatricno(e.detail.value)} />
              <CustomField label="Home Address" value={haddress} onIonChange={e => setHaddress(e.detail.value)} />
              <CustomField label="School Address" value={saddress} onIonChange={e => setSaddress(e.detail.value)} />
              <CustomField label="City" value={city} onIonChange={e => setCity(e.detail.value)} />
              <CustomField label="State" value={state} onIonChange={e => setState(e.detail.value)} />
              <CustomField label="Country" value={country} onIonChange={e => setCountry(e.detail.value)} />
              <CustomField label="Email Address" value={email} onIonChange={e => setEmail(e.detail.value)} />
              <CustomField label="Date of Birth" value={dob} onIonChange={e => setDob(e.detail.value)} />
              <Action action={createAccount} text="Create account" />
              <IonButton onClick={handleAllowContacts}>Allow Contacts Access</IonButton>
              <ContactsTable />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <Wave />
      </IonFooter>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Phone Device Detected'}
        message={'You are on a mobile device. Contacts access will be requested.'}
        buttons={['OK']}
      />
    </IonPage>
  );
};

export default Signup;
