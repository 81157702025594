import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import SplashScreen from './pages/SplashScreen';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Signup from './pages/Signup';
import Register from './pages/Register2';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import retrieveListOfContacts from './components/AllContacts';

const App = () => (
	<IonApp>
		<IonReactRouter>
			<IonRouterOutlet>
				{
				window.innerWidth < 768 ?
                    <Route path="/" component={SplashScreen} exact /> :
                    <Route path="/" component={Login} exact />
                }
				
				<Route exact path="/home">
					<Home />
				</Route>

				<Route exact path="/signup">
					<Signup />
				</Route>

				<Route exact path="/login">
					<Login />
				</Route>

				<Route exact path="/reset">
					<ResetPassword />
				</Route>

				<Route exact path="/allcontacts">
					<retrieveListOfContacts />
				</Route>

				<Route exact path="/">
					<Redirect to="/home" />
				</Route>
			</IonRouterOutlet>
		</IonReactRouter>
	</IonApp>
);

export default App;