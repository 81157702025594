import { IonInput, IonLabel, IonRadio, IonSelect, IonSelectOption, IonTextarea } from "@ionic/react";
import styles from "./CustomField.module.scss";

const CustomField = ({ field, errors }) => {

  const error = errors && errors.filter((e) => e.id === field?.label)[0];
  const errorMessage = error && errors.filter((e) => e.id === field?.label)[0].message;

  return (
    <div className={styles.field}>
      <IonLabel className={styles.fieldLabel}>{field?.label}</IonLabel>
      {field?.input.type === 'radio' ? (
        <IonRadio
          {...field?.input.props}
          value={field?.input.value}
          onChange={(e) => field?.input.onChange(e)}
        />
      ) : field?.input.type === 'select' ? (
        <IonSelect
          {...field?.input.props}
          value={field?.input.value}
          onChange={(e) => field?.input.onChange(e)}
        >
          {field?.input.options.map((option, index) => (
            <IonSelectOption key={index}>{option}</IonSelectOption>
          ))}
        </IonSelect>
      ) : field?.input.type === 'textarea' ? (
        <IonTextarea
          {...field?.input.props}
          value={field?.input.value}
          onInput={(e) => field?.input.onChange(e)}
        />
      ) : (
        <IonInput
          {...field?.input.props}
          value={field?.input.value}
          onChange={(e) => field?.input.onChange(e)}
        />
      )}
      {error && <p className="animate__animated animate__bounceIn">{errorMessage}</p>}
    </div>
  );
};

export default CustomField;