import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import './SplashScreen.css';

const SplashScreen = () => {
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setRedirect(true);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div>
    <iframe src="/splash_screen.html" style={{width: '100%', height: '100%',  position: 'absolute', top: 0, left: 0}} title="framer"></iframe>
    {redirect && <Redirect from="/" to="/home" />}
    </div>
  );
};

export default SplashScreen;
/* nvm use 21.6.1 */
