import React, { useState } from 'react';
import { IonContent, IonPage, IonHeader, IonToolbar, IonTitle, IonInput, IonButton, IonItem, IonLabel, IonList } from '@ionic/react';
/* import { Plugins } from '@capacitor/core';
const { Contacts } = Plugins;
const { SuperGreatPlugin } = Plugins; */
//import {Contacts} from '@capacitor-community/contacts';
import { Contacts } from '@capacitor-community/contacts';
import retrieveListOfContacts from '../components/AllContacts'

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contacts, setContacts] = useState([]);

  const isMobileDevice = () => {
    return /Mobi/i.test(navigator.userAgent);
  }

  const getContacts = async () => {
    const contacts = await Contacts.requestPermissions();
    if (contacts.permission === 'granted') {
      const contactsList = await Contacts.getContacts();
      return contactsList;
    } else {
      alert("Contacts API not supported in this browser.");
      return [];
    }
  }
 

  const handleRegistration = async (event) => {
    event.preventDefault();

    if (isMobileDevice()) {
      if (window.confirm("Do you want to allow the app to access your phonebook?")) {
        await getContacts();
      }
    }

    // Here you would normally send the form data to the server.
    console.log({ name, email, contacts });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Registration</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleRegistration}>
          <IonItem>
            <IonLabel position="floating">Name</IonLabel>
            <IonInput value={name} onIonChange={e => setName(e.detail.value)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Email</IonLabel>
            <IonInput type="email" value={email} onIonChange={e => setEmail(e.detail.value)}></IonInput>
          </IonItem>
          <IonButton type="submit" expand="block">Register</IonButton>
        </form>
        {contacts.length > 0 && (
          <IonList>
            <IonItem>
              <IonLabel>
                <h2>Contacts</h2>
              </IonLabel>
            </IonItem>
            {contacts.map((contact, index) => (
              <IonItem key={index}>
                <IonLabel>
                  <h2>{contact.displayName}</h2>
                  {contact.phoneNumbers && contact.phoneNumbers.map((phone, phoneIndex) => (
                    <p key={phoneIndex}>{phone.number}</p>
                  ))}
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Register;
